import React from 'react'

export default function ProgressBar(props) {

    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 150,
        width: '10px',
        backgroundColor: "#fff",
        borderRadius: 10,
        margin: 50,
      }
    
      const fillerStyles = {
        height: '100%',
        width: `${completed}px`,
        backgroundColor: '#C6DC8D',
        borderRadius: 'inherit',
        textAlign: 'right'
      }
    
      const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
      }

  return (
    <div style={containerStyles} className="tornillo">
          <div style={fillerStyles}>
            <span>.</span>
          </div>

    </div>
  )
}
