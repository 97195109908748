import React from 'react'
import {
    HashRouter,
    Routes,
    Route,
  } from "react-router-dom";
import App from './App';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';


export default function Main() {
  return (
    
    <>
   <HashRouter>  
    <Routes>
        <Route path="/" element={<Page1/>}/>
      <Route path="/game" element={<App/>}/>
      <Route path="/instructions" element={<Page2/>}/>
      <Route path="/final" element={<Page3/>}/>
      <Route path="/ganar" element={<Page4/>}/>

    </Routes>
  </HashRouter>  
  </>
  )
}
