import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Page4() {
    const navigate = useNavigate();

    useEffect(() => {
      setTimeout(() => {
        navigate("/")
      }, 10000);
    }, []);

return (
  <div className="App">

    <img  src='./ganar.jpg' alt='logo'/>
    <button className="btn btn-inicio" onClick={() => navigate('/')}>Inicio</button>
  </div>
)
}
