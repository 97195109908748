import React from 'react'
import "./App.scss";
import { Link } from "react-router-dom";

export default function Page1() {
  return (
    <div className='App'>
      <div className='Title'><h1>Straumann® says</h1></div>

      <div className='content'><p>¡Rete su memoria junto a Straumnn®!</p></div>

      <Link to="/instructions"><img className='btn' src='./btn1.png' alt='logo'></img></Link>
    </div>
  )
}
