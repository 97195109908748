import React from 'react'
import { useNavigate } from "react-router-dom";

export default function Page2() {

    const navigate = useNavigate();
  return (
    <div className="App">

        <img  src='./instrucciones.png' alt='logo'></img>
        <button className="btn btn-inicio" onClick={() => navigate('/')}>Inicio</button>
        <button className="btn btn-play" onClick={() => navigate('/game')}>Jugar</button>
    </div>
  )
}
